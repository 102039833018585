import React from "react";
import { graphql, Link } from "gatsby";
import "./style.scss";

export const query = graphql`
  query PostTemplate($slug: String!) {
    post: strapiConnectAtYouWebsiteBlogs(slug: { eq: $slug }) {
      strapiId
      title
      slug
      body
      chosen_date(formatString: "DD.MM.YYYY")
      image {
        publicURL
      }
    }
  }
`;

export default function PostTemplate({ data }) {
  const post = data.post;
  const { title, body, image, chosen_date } = post;

  return (
    <>
      <div className="blogTemplate">
        <h1 className="blogTemplate-title">{title}</h1>
        <div className="columns">
          <div className="column is-4 is-offset-4">
            <img src={image.publicURL} alt={title} />
            <div className="section" />
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-7">
            <b dangerouslySetInnerHTML={{ __html: chosen_date }} />
                <br />
                <br />
              <p className="description">                
                <div dangerouslySetInnerHTML={{ __html: body }} />
                <br />
                <Link to="/blog">Torna a Blog &amp; News</Link>
              </p>
              <div className="section" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
